<template>
  <div>
    <h1> Uber Surge Pricing Confirmation Success! </h1>
    <p> Please proceed request on Agent Dashboard. </p>
    <p> Confirmation Id: {{ $route.query.surge_confirmation_id }} </p>
  </div>
</template>

<script>
export default {
  name: 'UberConfirmation',
};
</script>
